<template>
  <header>
    <nav
      id="top-site-nav"
      class="navbar navbar-expand-xl navbar-dark bg-transparent pt-0 pb-0"
      :style="{ 'max-width': $root.maxWidth }"
    >
      <!-- <div class="container-lg container-fluid">
        <p class="h5 ms-auto mx-lg-0 text-phone">
          <span class="icon icon-phone">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: #ffffff;transform: ;msFilter:;"
            >
              <path
                d="M17 2H7c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM7 16.999V5h10l.002 11.999H7z"
              ></path>
            </svg>
          </span>
          613-234-1211
        </p>
      </div> -->
    </nav>
    <nav
      id="main-site-nav"
      class="navbar navbar-expand-xl navbar-dark bg-transparent pt-0"
      :style="{ 'max-width': $root.maxWidth }"
    >
      <div class="container-fluid px-0">
        <router-link class="navbar-brand" to="/"
          ><img
            src="../assets/logo-docs.png"
            class="img-fluid"
            width="250"
            alt="Logo - Docs"
        /></router-link>
        <button
          class="navbar-toggler me-lg-5"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mb-2 mb-lg-0 mt-lg-4 ms-auto">
            <!-- <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Dropdown
              </a>
              <ul
                class="dropdown-menu rounded-0 bg-dark"
                aria-labelledby="navbarDropdown"
              >
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a class="dropdown-item" href="#">Something else here</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a
                class="nav-link disabled"
                href="#"
                tabindex="-1"
                aria-disabled="true"
                >Disabled</a
              >
            </li> -->
            <template v-for="(item, index) in $root.mainMenu">
              <li
                @click="closeNav"
                v-if="!item.subMenu"
                class="nav-item px-lg-1 mt-0 mb-0 mt-lg-0 mx-lg-1"
                :key="index"
              >
                <router-link class="nav-link my-auto" :to="item.route">{{
                  item.title
                }}</router-link>
              </li>
              <li
                class="
                  dropdown
                  nav-item
                  px-lg-1
                  my-auto
                  mt-3
                  mb-0
                  mt-lg-0
                  mx-lg-1
                "
                v-else
                :key="index"
                @mouseover="menuHover"
              >
                <!-- <div class="nav-item px-lg-1 mt-3 mb-0 mt-lg-0 mx-lg-1"> -->
                <a
                  class="dropdown-toggle nav-link my-auto"
                  href="#"
                  :id="item.title.replace(' ', '-')"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ item.title }}
                </a>
                <!-- </div> -->
                <ul
                  class="dropdown-menu bg-dark rounded-0"
                  :aria-labelledby="item.title.replace(' ', '-')"
                >
                  <li v-for="subItem in item.subMenu" :key="subItem.title">
                    <router-link
                      class="dropdown-item nav-link"
                      :to="subItem.route"
                      >{{ subItem.title }}</router-link
                    >
                  </li>
                </ul>
              </li>
            </template>

            <!-- <li class="nav-item px-lg-1 mb-0 mt-lg-0 mx-lg-1 nav-language">
              <a class="nav-link my-auto" href="#">FR</a>
            </li> -->
            <!-- <li class="nav-item mb-0 mb-lg-0 px-lg-1 mx-lg-1">
              <a class="nav-link my-auto mx-auto btn-primary" href="#"
                >Book Appt</a
              >
            </li> -->
          </ul>
          <!-- <form class="d-flex">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> -->
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
/* eslint-disable */
export default {
  name: "Header",
  data() {
    return {
      view: {
        topOfPage: true,
      },
    };
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
    menuHover(e) {
      //console.log("menuHover", e);
    },
    closeNav() {
      let navMenu = document.querySelector("#navbarSupportedContent.show");
      if (!navMenu) return;
      navMenu.classList.remove("show");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
#main-site-nav {
  position: sticky;
  z-index: 1;
  width: 100%;
  top: 0;
  //   .navbar-toggler {
  //     // position: relative;
  //     // right: 12%;
  //     // padding: 0;
  //   }
}
</style>
